import { defineComponent, ref, h } from 'vue'

const normalizeSlot = (slotContent) =>
  slotContent.length === 1 ? slotContent[0] : slotContent

const LazyMount = defineComponent({
  name: 'LazyMount',
  inheritAttrs: false,
  suspensible: false,
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { slots }) {
    const isMounted = ref(false)
    return () => {
      if (props.show && !isMounted.value) {
        isMounted.value = true
      }
      if (isMounted.value) {
        return normalizeSlot(slots.default?.())
      }
      return h(false)
    }
  }
})

export default LazyMount
