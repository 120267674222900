import schttp from 'public/src/services/schttp'

export const getCccProductList = (data = {}, abortController) => {
  if(!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/productList/get',
    method: 'post',
    data,
    signal: abortController?.signal
  })
}

export const getCccProductAtom = (data = {}, headers = {}) => {
  if (!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/products/query',
    method: 'POST',
    data,
    headers
  })
}

export const getCccFlashSaleList = (data = {}, headers = {}) => {
  if(!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/flashSaleList/get',
    method: 'post',
    data,
    headers
  })
}

// 获取dailynew组件tab
export const getCccDailyNewDay = (data = {}) => {
  return schttp({
    url: '/api/ccc/dailyNewDay/get',
    method: 'get',
    params: data,
  })
}

// 查询点赞记录
export const getThumbRecord = (data = {}) => {
  if(!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/like/query',
    method: 'POST',
    data,
  })
}
// 点赞
export const thumbReport = (data = {}) => {
  if(!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/like/update',
    method: 'POST',
    data,
  })
}
// 取消点赞
export const thumbCancel = (data = {}) => {
  if(!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/like/delete',
    method: 'POST',
    data,
  })
}

// 获取订阅组件手机区号列表
export const getCccCountryList = () => {
  return schttp({
    url: '/api/ccc/countryList/query',
    method: 'get'
  })
}

// 订阅组件发送短信
export const sendCccVerifyCode = (data) => {
  return schttp({
    url: '/api/ccc/sendCccVerifyCode/create',
    method: 'POST',
    data,
  })
}

// 订阅组件提交
export const postCccVerifySub = (data) => {
  return schttp({
    url: '/api/ccc/postCccVerifySub/create',
    method: 'POST',
    data,
  })
}

// 查询订阅列表
export const postCccSubList = (data) => {
  return schttp({
    url: '/api/ccc/postCccSubList/query',
    method: 'POST',
    data,
  })
}

// 意见收集组件-查询用户提交状态
export const getUserSurveyRecord = (data) => {
  return schttp({
    url: '/api/ccc/userSurveyRecord/get',
    method: 'get',
    params: data,
  })
}

// 意见收集组件提交问卷答案
export const postSurveyCommit = (data) => {
  return schttp({
    url: '/api/ccc/surveyCommit/create',
    method: 'POST',
    data,
  })
}

// pk组件投票
export const postPkVote = (data) => {
  return schttp({
    url: '/api/ccc/PkVote/create',
    method: 'POST',
    data,
  })
}

// cccx 推荐组件
export const getCccProductRecommendList = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/cccProductRecommendList/query',
      method: 'POST',
      data,
      timeout: 10 * 1000
    }) 
  } catch(e) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 商品流
export const getCccRecommendFlowCompose = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/recommendFlowCompose/get',
      method: 'POST',
      data,
      timeout: 20000, // 20s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 品牌推荐
export const getCccBrandRecommend = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/brandRecommend/query',
      method: 'POST',
      data,
      timeout: 10000, // 10s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 品牌推荐
export const getCccCateLink = async (ids) => {
  try {
    return await schttp({
      method: 'POST',
      url: `/api/ccc/cateLink/get`,
      data: { ids }
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// cccx 推荐榜
export const getRankRecommend = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/rankRecommend/query',
      method: 'POST',
      data,
      timeout: 10 * 1000
    }) 
  } catch(e) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}


// 获取 - 店铺信息
export const getStoreBase = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/base/query',
    params: { requestType: 'storeBase' },
    data
  })
}


// 排行榜聚合
export const getRankCommon = async (data) => {
  try {
    return await schttp({
      method: 'POST',
      url: `/api/ccc/rank/query`,
      data,
      timeout: 10000, // 10s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request',
      error
    })
  }
}

// 获取 - 店铺组装数据
export const getStoreCompose = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/base/query',
    params: { requestType: 'storeCompose' },
    data
  })
}

// 更新 - 关注状态
export const updateFollowState = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/follow/update',
    params: { requestType: 'storeFollow' },
    data
  })
}

// 获取 - 关注状态
export const getStoreFollowState = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/follow/get',
    params: { requestType: 'storeFollowState' },
    data
  })
}

// 批量获取 - 关注状态
export const getStoreFollowStateBatch = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/follow/query',
    params: { requestType: 'storeFollowStateBatch' },
    data
  })
}

// 获取 - 店铺商品列表
export const getStoreProductList = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/product/get',
    params: { requestType: 'storeProductList' },
    data
  })
}

// 批量获取 - 店铺商品列表 
export const getStoreProductListBatch = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/product/query',
    params: { requestType: 'storeProductListBatch' },
    data
  })
}

//  ====================== 店铺页组件 Start ======================
// 批量获取 - 单品组件获取商品
export const getSingleProductList = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/singleProduct/query',
    data
  })
}

// 获取店铺活动组件商品数据（选品列表）
export const getShopActivityList = async (data = {}) => {
  try {
    return await schttp({
      url: '/api/ccc/shopActivityList/get',
      method: 'POST',
      data,
    })
  } catch (e) {
    return Promise.resolve({
      code: -1,
      msg: 'shop activity error request'
    })
  }
}
//  ====================== 店铺页组件 End ======================

// 品牌推荐
export const getCccCategoryRecommendDynamic = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/categoryRecommendDynamic/query',
      method: 'POST',
      data
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 店铺品类推荐
export const getRecommendFacadeData = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/storeCategoryRecommend/query',
      method: 'POST',
      data
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

export const getSheinClubData = async () => {
  try {
    return await schttp({
      url: '/api/ccc/sheinClub/get',
      method: 'get',
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}
// 获取 - 设计师组件推荐列表聚合数据
export const getSheinXRecommend = async (data = {}, headers = {}) => {
  try {
    return await schttp({
      url: '/api/ccc/sheinxRecommend/get',
      method: 'GET',
      params: data,
      headers,
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 领取试用
export const getTrialData = async (data) => {
  try {
    const res = await schttp({
      method: 'POST',
      url: '/api/prime/postFreeTrial/create',
      data
    })
    return res
  } catch (error) {
    return error
  }
}

// 1/2 组件
export const getCccHalfListData = (data = {}, headers = {}) => {
  if(!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/halfList/get',
    method: 'post',
    data,
    headers
  })
}

// 请求1/3组件数据
export const getCccOneThirdCompData = async (data = {}, headers = {}) => {
  try {
    return await schttp({
      url: '/api/ccc/oneThird/get',
      method: 'POST',
      data,
      headers
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 请求历史最低价最贱
export const getCccLowestPriceData = async (data = {}, headers = {}) => {
  try {
    return await schttp({
      url: '/api/ccc/lowestPrice/query',
      method: 'POST',
      data,
      headers
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

//  ====================== sheinx 投票组件 Start ======================
// 获取 - 组装投票详情
export const getSheinxVoteCompose = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/sheinxVote/detail/query',
    params: { requestType: 'sheinxVoteCompose' },
    data
  })
}

// 获取 - 用户投票信息
export const getSheinxVoteUser = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/sheinxVote/userInfo/get',
    params: { requestType: 'sheinxVoteUser' },
    data
  })
}

// 更新 - 投票
export const sheinxVoteReport = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/sheinxVote/report/update',
    params: { requestType: 'sheinxVoteReport' },
    data
  })
}
//  ====================== sheinx 投票组件 End ======================

//  ======================  专题抽奖相关 Start  ======================
// 查询抽奖结果
export const getLotteryResult = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/lotteryRecord/query',
    params: { requestType: 'lotteryRecord' },
    data
  })
}

// 查询奖品信息
export const getLotteryPrizePool = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/lotteryPrizePool/query',
    params: { requestType: 'lotteryPrizePool' },
    data
  })
}

// 查询 奖品信息 & 抽奖结果 组合数据
export const getPrizePoolAndRecordCompose = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/prizePoolAndRecordCompose/query',
    params: { requestType: 'prizePoolAndRecordCompose' },
    data
  })
}

// 抽奖动作
export const reportLotteryAction = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/lotteryAction/update',
    params: { requestType: 'lotteryAction' },
    data
  })
}
//  ======================  专题抽奖相关 End  ======================

// 请求(更新)1/3组件的券包数据
export const getCccOneThirdCouponCompData = async (data = {}, headers = {}) => {
  try {
    return await schttp({
      url: '/api/ccc/oneThirdCoupon/get',
      method: 'POST',
      data,
      headers
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 获取 - 新闻文章列表数据
export const getNewsRoomArticleList = async (data = {}, headers = {}) => {
  try {
    return await schttp({
      url: '/api/ccc/articleList/query',
      method: 'POST',
      data,
      params: { requestType: 'newsRoomArticleList' },
      headers,
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}
// 不贵组件
export const getCccSuperDealsList = (data = {}, headers = {}) => {
  if (!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/superDealsList/get',
    method: 'POST',
    data,
    headers
  })
}

// 查询推客券券包信息
export const getNewuserCouponPackageInfo = async (data = {}) => {
  try {
    return await schttp({
      url: '/api/ccc/newuserCouponPackage/query',
      method: 'POST',
      params: { requestType: 'couponList' },
      data,
    })
  }catch(e) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 绑定推客券券包信息
export const bindNewuserCouponPackageInfo = async (data = {}) => {
  try {
    return await schttp({
      url: '/api/ccc/newuserCouponPackage/update',
      method: 'POST',
      params: { requestType: 'receiveCoupons' },
      data,
    })
  }catch(e) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}
